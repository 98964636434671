import { Typography } from '@mui/material';
import color from '@theme/Colors';
import { CSSProperties } from 'react';

interface TextBackdropProps {
    text: string;
    textColor?: CSSProperties['color'];
}
export default function TextBgBackdrop(props: TextBackdropProps) {
    const { text, textColor } = props;
    return (
        <Typography
            fontWeight={500}
            borderRadius={'4px'}
            sx={{ opacity: 0.9 }}
            color={textColor ?? color.containedButtonString}
            bgcolor={color.white}
            p={1}
        >
            {text}
        </Typography>
    );
}
