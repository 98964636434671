import color from '@theme/Colors';
import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

function CameraIcon(props: Props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? '24px'}
            height={props.height ?? '24px'}
            // fill="none"
            viewBox="0 0 24 24"
            className="icon-svg-fill"
            fill={"currentColor"}
        >
            <path d="M4.388 20.763l.391-.64-.391.64zm-1.651-1.65l.64-.392-.64.392zm18.526 0l-.64-.392.64.392zm-1.65 1.65l-.392-.64.392.64zM21.25 8.43V13h1.5V8.429h-1.5zM13.5 20.75h-3v1.5h3v-1.5zM2.75 13V8.429h-1.5V13h1.5zm13-10.25v.179h1.5V2.75h-1.5zm-7.5.179V2.75h-1.5v.179h1.5zm0-.179a1 1 0 011-1V.25a2.5 2.5 0 00-2.5 2.5h1.5zm6.5-1a1 1 0 011 1h1.5a2.5 2.5 0 00-2.5-2.5v1.5zm-8.821 3.5A2.321 2.321 0 008.25 2.929h-1.5a.821.821 0 01-.821.821v1.5zm4.571 15.5c-1.651 0-2.837 0-3.762-.089-.914-.087-1.495-.253-1.959-.537l-.783 1.279c.738.452 1.577.654 2.6.752 1.013.096 2.282.095 3.904.095v-1.5zM1.25 13c0 1.622 0 2.892.095 3.904.098 1.023.3 1.861.752 2.6l1.28-.783c-.285-.464-.451-1.045-.538-1.96-.088-.924-.089-2.11-.089-3.761h-1.5zm3.53 7.124a4.25 4.25 0 01-1.404-1.403l-1.279.783a5.75 5.75 0 001.899 1.899l.783-1.28zM21.25 13c0 1.651 0 2.837-.089 3.762-.087.914-.253 1.495-.537 1.959l1.279.783c.452-.739.654-1.577.752-2.6.096-1.012.095-2.282.095-3.904h-1.5zm-7.75 9.25c1.622 0 2.892 0 3.904-.096 1.023-.097 1.861-.299 2.6-.751l-.783-1.28c-.464.285-1.045.451-1.96.538-.924.088-2.11.089-3.761.089v1.5zm7.124-3.53a4.249 4.249 0 01-1.403 1.404l.783 1.279a5.75 5.75 0 001.899-1.899l-1.28-.783zM22.75 8.43a4.679 4.679 0 00-4.679-4.679v1.5a3.179 3.179 0 013.179 3.179h1.5zM18.071 3.75a.821.821 0 01-.821-.821h-1.5a2.321 2.321 0 002.321 2.321v-1.5zM2.75 8.429A3.179 3.179 0 015.929 5.25v-1.5A4.679 4.679 0 001.25 8.429h1.5zm4.5 4.071A4.75 4.75 0 0012 17.25v-1.5a3.25 3.25 0 01-3.25-3.25h-1.5zM12 17.25a4.75 4.75 0 004.75-4.75h-1.5A3.25 3.25 0 0112 15.75v1.5zm4.75-4.75A4.75 4.75 0 0012 7.75v1.5a3.25 3.25 0 013.25 3.25h1.5zM12 7.75a4.75 4.75 0 00-4.75 4.75h1.5A3.25 3.25 0 0112 9.25v-1.5zm-2.75-6h5.5V.25h-5.5v1.5zM17 8.5a1 1 0 102 0 1 1 0 00-2 0z"></path>
        </svg>
    );
}

export default CameraIcon;
