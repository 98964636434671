import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraTaskAttachment } from '@EcamModel/model/CameraTaskAttachment';
import { AxiosInstance } from 'axios';

export class CameraTaskAttachmentHttpController extends BaseHttpController<CameraTaskAttachment> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-task-attachment', client);
    }

    async createCameraTaskAttachments(props: FormData) {
        return await this.doPost({
            path: 'create',
            body: props,
            config: {
                headers: { 'Content-Type': 'multipart/form-data' },
            },
        }).then((res) => res.data);
    }
}
