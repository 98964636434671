import MicrosoftIcon from '@assets/jsx-icon/MicrosoftIcon';
import { useMsal } from '@azure/msal-react';
import MyAppBar from '@components/header/MyAppBar';
import { loginRequest } from '@configs/authConfig';
import { loginBackground } from '@configs/ImageFromEnv';
import { Button, Container, Stack, Typography } from '@mui/material';
import color from '@theme/Colors';
import React from 'react';

export default function Login() {
    const { instance } = useMsal();

    // useEffect(() => {
    //     instance.loginRedirect(loginRequest).catch((e) => {
    //         console.error(e);
    //     });
    // }, []);

    const handleLogin = () => {
        instance.loginPopup(loginRequest).then((res) => {
            console.log(res);
        });
    };
    return (
        <Stack>
            <MyAppBar isShowLabelAccount={false} isShowLabelLanguage={true} />
            <Stack
                direction={'row'}
                minHeight={'calc(100vh - 63px)'}
                width={'calc(100vW - 8px)'}
                className="App"
                px={{ xs: 2, sm: 1, md: 0 }}
                sx={{
                    background: `url("${loginBackground}")`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '100vh',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                }}
            >
                <Stack
                    sx={{
                        padding: '40px 24px',
                        width: '600px',
                        background: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(4.5px)',
                        borderRadius: '10px',
                        direction: 'column',

                        gap: 3,
                    }}
                >
                    <Typography variant="h1" textAlign={'center'}>
                        Login
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: color.white,
                            color: color.containedButtonString,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: color.white,
                            fontWeight: 400,
                            '&:hover': {
                                boxShadow: 'none',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                color: color.textPrimary,
                                backgroundColor: color.lightPrimary,
                                borderColor: color.lightPrimary,
                            },
                        }}
                        onClick={() => handleLogin()}
                        startIcon={<MicrosoftIcon />}
                    >
                        Sign in with Microsoft
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}
