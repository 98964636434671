import { CameraPriority } from './../../../submodules/ecam-ops-base-model/eCam-model/model/Camera';
import {
    CameraServiceType,
    CaptureInfoFrequency,
    CaptureInfoPostType,
    CaptureInfoUploadMode,
    CellularInfoNetworkType,
    Status,
    WifiStatus,
} from '@EcamModel/model';
import { ConsolidateCameraMonitoring } from '@EcamModel/model/CameraOverview';
import { BaseSelect } from '@pages/cameras/list';
import { TableInfoContent } from '@pages/overview/hook/ReportsAnalyzingCriteriaAndPcnProvider';
import { SmartFilter } from '@pages/overview/smartFilterHighLight/configs';
import { DataTypes, SortCriterion, SortMode } from 'src/helpers/sortColumTable';

export interface RowDataColumn {
    row: ConsolidateCameraMonitoring;
    columnId: keyof ConsolidateCameraMonitoring;
}

export interface PercentageRateProps {
    data: RowDataColumn;
    isCheckRate?: boolean;
}

export interface BackgroundColorAbsoluteProps {
    value: React.ReactNode;
    isCheckOnClick?: boolean;
    rowData: keyof ConsolidateCameraMonitoring;
    data: ConsolidateCameraMonitoring;
}

export enum CameraMonitoringMetric {
    PotentialContraventions = 'PotentialContraventions',
    AvgDailyPhotosTaken = 'AvgDailyPhotosTaken',
    PotentialRate = 'PotentialRate',
    ApprovalRate = 'ApprovalRate',
    CancellationRate = 'CancellationRate',
}

export const cameraStatusMapping = {
    [Status.Active]: 'Active',
    [Status.Inactive]: 'Inactive',
    [Status.NotAvailable]: 'Not available',
    [Status.Deleted]: 'Deleted',
};

export const netWorkingMapping = {
    [CellularInfoNetworkType.AUTO]: 'Auto',
    [CellularInfoNetworkType.ONLY_2G]: '2G only',
    [CellularInfoNetworkType.ONLY_3G]: '3G only',
    [CellularInfoNetworkType.ONLY_4G]: '4G only',
};

export const uploadModeMapping = {
    [CaptureInfoUploadMode.TIMING]: 'Timing',
    [CaptureInfoUploadMode.IMMEDIACY]: 'Immediacy',
};

export const wifiStatusMapping = {
    [WifiStatus.Connected]: 'Connected',
    [WifiStatus.DisConnected]: 'Disconnected',
};

export const postTypeMapping = {
    [CaptureInfoPostType.HTTP]: 'HTTP',
    [CaptureInfoPostType.MQTT]: 'MQTT',
};

export const cameraServiceMapping = {
    [CameraServiceType.ANPRTechOps]: 'ANPR',
    [CameraServiceType.ECamTechOps]: 'eCam',
};

export const frequencyTimeMapping = {
    [CaptureInfoFrequency.ONCE_A_DAY]: 'Once a Day',
    [CaptureInfoFrequency.TWICE_A_DAY]: 'Twice a Day',
};

export interface CameraOverviewPopup {
    cameraName: string;
    cameraId: number;
    locationName?: string;
}

export enum Mode {
    create,
    edit,
}

export enum TypeFilter {
    SMART,
    HIGHLIGHT,
    BATTERY,
    TASK,
}

export const statuses: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Active',
        Value: Status.Active,
    },
    {
        Id: 2,
        Name: 'Inactive',
        Value: Status.Inactive,
    },
    {
        Id: 3,
        Name: 'Not available',
        Value: Status.NotAvailable,
    },
    {
        Id: 4,
        Name: 'Deleted',
        Value: Status.Deleted,
    },
];

export const cameraVersions: BaseSelect[] = [
    {
        Id: 1,
        Name: '1',
        Value: 1,
    },
    {
        Id: 2,
        Name: '2',
        Value: 2,
    },
];

export const cameraDeleted: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Active',
        Value: 'Active',
    },
    {
        Id: 2,
        Name: 'Deleted',
        Value: 'Deleted',
    },
];

export const uploadMode: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Immediacy',
        Value: CaptureInfoUploadMode.IMMEDIACY,
    },
    {
        Id: 2,
        Name: 'Timing',
        Value: CaptureInfoUploadMode.TIMING,
    },
];

export const serviceType: BaseSelect[] = [
    {
        Id: 1,
        Name: 'eCam TechOps',
        Value: CameraServiceType.ECamTechOps,
    },
    {
        Id: 2,
        Name: 'ANPR TechOps',
        Value: CameraServiceType.ANPRTechOps,
    },
];

export const monitoredMetrics: (keyof ConsolidateCameraMonitoring)[] = Object.values(CameraMonitoringMetric);

export const dataTypesCameraOverview: { [key in keyof ConsolidateCameraMonitoring]: DataTypes } = {
    // location
    LocationId: 'number',
    LocationName: 'string',

    // zone
    ZoneId: 'number',
    ZoneName: 'string',

    // camera
    CameraId: 'enum',
    CameraName: 'string',
    CameraStatus: 'enum',
    CameraDeployDate: 'date',
    CameraDeleted: 'date',
    CameraService: 'enum',
    CameraNoteCount: 'number',
    CameraTaskCount: 'number',
    CameraTaskOverDueCount: 'number',
    IsVerificationsEnabled: 'boolean',
    IP: 'string',
    LatestPhotoTime: 'date',
    TimeZone: 'string',

    // solar camera
    DevMac: 'string',
    CameraVersion: 'string',
    LastContact: 'date',
    LastWifiStatus: 'enum',

    // solar time info
    ServerTime: 'date',
    CameraTime: 'date',
    DifferentTime: 'number',
    DifferentMinute: 'number',
    SetTimeAutomatically: 'number',

    // solar capture info
    UploadMode: 'enum',
    CaptureTimeout: 'number',
    RadarSensitivity: 'number',
    LowSpeedLimit: 'number',
    Indicator: 'enum',
    PostType: 'enum',
    Frequency: 'enum',
    PostCaptureInterval: 'number',
    PostCaptureNum: 'number',

    // solar system info
    Firmware: 'string',

    // solar alarm ROI
    CaptureInterval: 'number',

    // solar cellular info
    NetworkType: 'enum',
    Apn: 'string',

    // solar cellular status
    Iccid: 'string',
    ConnectStatus: 'string',
    SimStatus: 'string',
    SignalStrength: 'number',

    // Avg Daily
    AvgDailyWorkedHours: 'number',
    AvgDailyPhotosTaken: 'number',

    // Total Taken Photos
    TotalPhotosTaken: 'number',

    // Avg TotalContraventions
    PotentialContraventions: 'number',
    InprogressContraventions: 'number',
    CancelledContraventions: 'number',
    ApprovedContraventions: 'number',

    // Rate TotalContraventions
    PotentialRate: 'number',
    ApprovalRate: 'number',
    CancellationRate: 'number',

    // solar Ping
    Pingable: 'boolean',

    // Upload mechanism
    ImagesLeft: 'number',

    // Purpose type
    PurposeType: 'enum',

    // Prediction date
    PredictedBatteryRunOutDate: 'date',

    // Battery
    AvgBattery: 'number',
    CurrentBattery: 'number',

    // Power supply
    CameraPowerSupply: 'enum',

    // Priority
    CameraPriority: 'number',

    CameraSDUsed: 'number',
    CameraSDFreeSpace: 'number',
};

export const getCellStyles = <T>(
    id: keyof T,
    dataTypes: { [key in keyof T]: DataTypes },
    stickyLeftFields: Array<keyof T>,
    alignmentFields: { [key in keyof T]?: React.CSSProperties['textAlign'] },
    stickyRightFields?: Array<keyof T>,
    isSelected?: boolean
) => {
    const columnType = dataTypes[id];
    const minWidth = columnType === 'string' ? '10px' : 'max-content';

    const isStickyLeftColumn = stickyLeftFields.includes(id);
    const isStickyRightColumn = stickyRightFields?.includes(id);

    const align: React.CSSProperties['textAlign'] = alignmentFields[id] ?? 'center';

    const leftPosition = isStickyLeftColumn
        ? stickyLeftFields.slice(0, stickyLeftFields.indexOf(id)).reduce(
              (acc, key) => {
                  const columnWidth = dataTypes[key] === 'string' ? 200 : dataTypes[key] === 'number' ? 85 : 50;
                  return acc + columnWidth;
              },
              isSelected ? 52 : 0
          )
        : undefined;

    const rightPosition = isStickyRightColumn
        ? stickyRightFields?.slice(0, stickyRightFields.indexOf(id)).reduce((acc, key) => {
            const columnWidth = dataTypes[key] === 'string' ? 200 : dataTypes[key] === 'number' ? 75 : 50;
            return acc + columnWidth;
        }, 0)
        : undefined;

    return {
        align: align as 'left' | 'center',
        sx: {
            padding: '4px 14px !important',
            backgroundColor: '#f0f0f0',
            minWidth: minWidth,
            ...(isStickyLeftColumn && {
                minWidth: columnType === 'string' ? '200px' : '50px',
                position: 'sticky',
                zIndex: stickyLeftFields.indexOf(id) === 0 ? 2 : 3,
                left: `${leftPosition}px`,
            }),
            ...(isStickyRightColumn && {
                minWidth: '50px',
                position: 'sticky',
                zIndex: stickyRightFields?.indexOf(id) === 0 ? 2 : 3,
                right: `${rightPosition}px`,
            }),
        },
    };
};

export const getColumnStyles = <T>(
    columnId: keyof T,
    columnDataTypes: { [key in keyof T]: DataTypes },
    stickyLeftFields: Array<keyof T>,
    stickyRightFields?: Array<keyof T>,
    isSelected?: boolean
) => {
    const columnDataType = columnDataTypes[columnId];
    const minimumWidth = columnDataType === 'number' ? '60px' : '100px';

    const isLeftStickyColumn = stickyLeftFields.includes(columnId);
    const isStickyRightColumn = stickyRightFields?.includes(columnId);

    const leftPosition = isLeftStickyColumn
        ? stickyLeftFields.slice(0, stickyLeftFields.indexOf(columnId)).reduce(
              (acc, key) => {
                  const columnWidth =
                      columnDataTypes[key] === 'string' ? 200 : columnDataTypes[key] === 'number' ? 85 : 50;
                  return acc + columnWidth;
              },
              isSelected ? 52 : 0
          )
        : undefined;

    return {
        padding: '4px 8px !important',
        backgroundColor: '#FAFAFA',
        minWidth:
            isLeftStickyColumn || isStickyRightColumn ? (columnDataType === 'number' ? '85px' : '50px') : minimumWidth,
        position: isLeftStickyColumn || isStickyRightColumn ? 'sticky' : 'unset',
        zIndex:
            isLeftStickyColumn || isStickyRightColumn
                ? isLeftStickyColumn
                    ? stickyLeftFields.indexOf(columnId) === 0
                        ? 2
                        : 3
                    : stickyRightFields?.indexOf(columnId) === 0
                        ? 2
                        : 3
                : 1,
        left: `${leftPosition}px`,
        right: isStickyRightColumn ? (stickyRightFields?.indexOf(columnId) === 0 ? 0 : '50px') : 'unset',
    };
};

const determineDataType = (value: any): DataTypes => {
    if (typeof value === 'string') {
        return 'string';
    }
    if (typeof value === 'number') {
        return 'number';
    }
    if (value instanceof Date) {
        return 'date';
    }
    if (Array.isArray(value)) {
        return 'array';
    }
    if (value && typeof value === 'object') {
        return 'object';
    }
    if (typeof value === 'boolean') {
        return 'boolean';
    }
    return 'any';
};

export const inferDataTypes = <T>(rows: T[]): { [K in keyof T]: DataTypes } => {
    if (!rows || rows.length === 0) {
        return {} as { [K in keyof T]: DataTypes };
    }

    const dataTypes: Partial<{ [K in keyof T]: DataTypes }> = {};
    const sampleRow = rows[0];

    for (const key in sampleRow) {
        const value = sampleRow[key];
        dataTypes[key] = determineDataType(value);
    }

    return dataTypes as { [K in keyof T]: DataTypes };
};

export const getColumnAlignment = <T>(
    columnId: keyof T,
    alignmentFields?: { [key in keyof T]?: 'inherit' | 'center' | 'left' | 'justify' | 'right' | undefined },
    leftAlignFields?: Array<keyof T>
): 'inherit' | 'center' | 'left' | 'justify' | 'right' | undefined => {
    return alignmentFields?.[columnId] ?? (leftAlignFields?.includes(columnId) ? 'left' : 'center');
};

export const doesFieldExistInModel = <T>(headCellId: keyof T, model: Array<keyof T>): boolean => {
    return model.includes(headCellId);
};

export const handleRequestSort = <T>(
    property: keyof T,
    orderBy: SortCriterion<T>[],
    onSetOrderBy: (value: SortCriterion<T>[]) => void,
    isMultiSort: boolean = false
) => {
    if (!isMultiSort) {
        const isAsc = orderBy.length > 0 && orderBy[0].property === property && orderBy[0].order === SortMode.ASC;
        onSetOrderBy([{ property, order: isAsc ? SortMode.DESC : SortMode.ASC }]);
        return;
    }

    const existingIndex = orderBy.findIndex((item) => item.property === property);
    const newOrderBy = [...orderBy];

    if (existingIndex > -1) {
        const isAsc = newOrderBy[existingIndex].order === SortMode.ASC;
        isAsc ? (newOrderBy[existingIndex].order = SortMode.DESC) : newOrderBy.splice(existingIndex, 1);
        onSetOrderBy(newOrderBy);
    } else {
        newOrderBy.push({ property, order: SortMode.ASC });
        onSetOrderBy(newOrderBy);
    }
};

export const numberOfVersionsForOverview: string = '1.0.6';
export const localStorageCustomFieldsKey: string = 'eCamConfigCustomFields';
export const localStorageSmartFilterKey: string = 'smartFilters';
export const localStorageHighlightFilterKey: string = 'highlightFilters';
export const activeItemFilterKey: string = 'activeItemFilter';

export const getColumnsFieldCustom = (): TableInfoContent<keyof ConsolidateCameraMonitoring>[] => [
    {
        id: 'CameraDeployDate',
        label: 'Deployment\ndate',
        version: numberOfVersionsForOverview,
        type: 'Date',
        tooltipHover: 'Deployment date',
    },
    {
        id: 'TotalPhotosTaken',
        label: 'Total\nphotos',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Total photos',
    },
    {
        id: 'AvgDailyPhotosTaken',
        label: 'Avg\nphotos',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Avg. photos',
    },
    {
        id: 'PotentialContraventions',
        label: 'Potential',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Potential contraventions',
    },
    {
        id: 'InprogressContraventions',
        label: 'Verification',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Contraventions in verification',
    },
    {
        id: 'ApprovedContraventions',
        label: 'Approved',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Contraventions approved',
    },
    {
        id: 'CancelledContraventions',
        label: 'Cancelled',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Contraventions cancelled',
    },
    {
        id: 'PotentialRate',
        label: 'Potential\nrate',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Rate of potential contraventions',
    },
    {
        id: 'ApprovalRate',
        label: 'Approval\nrate',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Rate of approved contraventions',
    },
    {
        id: 'CancellationRate',
        label: 'Cancel\nrate',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Rate of cancel contraventions',
    },
    {
        id: 'Frequency',
        label: 'Photo\n upload',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Photos upload time',
    },
    {
        id: 'PostCaptureInterval',
        label: 'Photo\ninterval',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Capture photos interval',
    },
    {
        id: 'PostCaptureNum',
        label: 'Photos\nper\nevent',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Number of capture photos per event',
    },
    {
        id: 'CameraVersion',
        label: 'Camera\nversion',
        version: numberOfVersionsForOverview,
        type: 'String',
        tooltipHover: 'Camera version',
    },
    {
        id: 'CameraStatus',
        label: 'Status',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Operational status',
    },
    {
        id: 'AvgDailyWorkedHours',
        label: 'Uptime\n(h)',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Avg. uptime (h)',
    },
    {
        id: 'PurposeType',
        label: 'Purpose',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Purpose type',
    },
    {
        id: 'CameraPowerSupply',
        label: 'Power\n supply',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Power supply',
    },
    {
        id: 'ServerTime',
        label: 'Server\ntime',
        version: numberOfVersionsForOverview,
        type: 'Date',
        tooltipHover: 'Server time',
    },
    {
        id: 'CameraTime',
        label: 'Camera\ntime',
        version: numberOfVersionsForOverview,
        type: 'Date',
        tooltipHover: 'Camera time',
    },
    {
        id: 'DifferentMinute',
        label: 'Time\ndifference',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Time difference',
    },
    {
        id: 'PredictedBatteryRunOutDate',
        label: 'Battery\ndate',
        version: numberOfVersionsForOverview,
        type: 'Date',
        tooltipHover: 'Predicted battery depletion date',
    },
    {
        id: 'ZoneName',
        label: 'Zone',
        version: numberOfVersionsForOverview,
        type: 'String',
        tooltipHover: 'Zone',
    },
    {
        id: 'SetTimeAutomatically',
        label: 'Auto\ntime',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Auto time',
    },
    {
        id: 'CaptureInterval',
        label: 'Capture\ninterval',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Capture interval',
    },
    {
        id: 'Firmware',
        label: 'Firmware',
        version: numberOfVersionsForOverview,
        type: 'String',
        tooltipHover: 'Firmware',
    },
    {
        id: 'DevMac',
        label: 'MAC',
        version: numberOfVersionsForOverview,
        type: 'String',
        tooltipHover: 'Device MAC',
    },
    {
        id: 'NetworkType',
        label: '4G/3G/2G',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Network mode 4G/3G/2G',
    },
    {
        id: 'CurrentBattery',
        label: 'Latest\nbattery',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Latest battery',
    },
    {
        id: 'AvgBattery',
        label: 'Avg.\nBattery',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Avg. Battery',
    },
    {
        id: 'LastContact',
        label: 'Last\ncontacted',
        version: numberOfVersionsForOverview,
        type: 'Date',
        tooltipHover: 'Last contact',
    },
    {
        id: 'Apn',
        label: 'APN',
        version: numberOfVersionsForOverview,
        type: 'String',
        tooltipHover: 'APN',
    },
    {
        id: 'Iccid',
        label: 'ICCID',
        version: numberOfVersionsForOverview,
        type: 'String',
        tooltipHover: 'Integrated Circuit Card ID',
    },
    {
        id: 'UploadMode',
        label: 'Upload\n mode',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Upload mode',
    },
    {
        id: 'Pingable',
        label: 'Pingable',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Latest ping',
    },
    {
        id: 'ConnectStatus',
        label: 'Connect\n status',
        version: numberOfVersionsForOverview,
        type: 'String',
        tooltipHover: 'Connect status',
    },
    {
        id: 'LastWifiStatus',
        label: 'Network\n status',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Network status',
    },
    {
        id: 'SignalStrength',
        label: 'Signal',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Current signal strength',
    },
    {
        id: 'SimStatus',
        label: 'Sim\nstatus',
        version: numberOfVersionsForOverview,
        type: 'String',
        tooltipHover: 'SIM status',
    },
    {
        id: 'CameraService',
        label: 'Service',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Type service',
    },
    {
        id: 'IsVerificationsEnabled',
        label: 'PCNs',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Gen PCNs',
    },
    {
        id: 'PostType',
        label: 'Mechanism',
        version: numberOfVersionsForOverview,
        type: 'List',
        tooltipHover: 'Mechanism type',
    },
    {
        id: 'CameraSDUsed',
        label: 'Camera \nSD used',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'SD Used',
    },
    {
        id: 'CameraSDFreeSpace',
        label: 'Camera \nSD freespace',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'SD Freespace',
    },
    {
        id: 'ImagesLeft',
        label: 'Images\nleft',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Images left in SD',
    },
    {
        id: 'LatestPhotoTime',
        label: 'Latest\nphoto',
        version: numberOfVersionsForOverview,
        type: 'Date',
        tooltipHover: 'Time of the last photo taken',
    },
    {
        id: 'CameraTaskCount',
        label: 'Task',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Task',
    },
    {
        id: 'CameraTaskOverDueCount',
        label: 'Task\noverdue',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Task overdue',
    },
    {
        id: 'CameraNoteCount',
        label: 'Notes',
        version: numberOfVersionsForOverview,
        type: 'Number',
        tooltipHover: 'Notes',
    },
];

export const customFieldIdsIncludes: (keyof ConsolidateCameraMonitoring)[] = ['CameraTaskCount'];
export const newTaskFilterData = getColumnsFieldCustom().filter((field) => customFieldIdsIncludes.includes(field.id));
export const newSmartFilter: SmartFilter[] = [
    {
        type: 'Number',
        options: [
            {
                field: ['CameraTaskCount'],
                condition: 'Greater Than Or Equal',
                value: 1,
            },
        ],
    },
];

export const batteryFieldIdsIncludes: (keyof ConsolidateCameraMonitoring)[] = [
    'AvgDailyWorkedHours',
    'CurrentBattery',
    'AvgBattery',
    'LastContact',
];
export const newBatteryFilterData = getColumnsFieldCustom().filter((field) =>
    batteryFieldIdsIncludes.includes(field.id)
);
