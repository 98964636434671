import { IconButton, Tooltip } from '@mui/material';
import { DangerTooltip } from '@pages/cameras/list/components/TooltipDangerIconButton';

import React from 'react';

export default function BaseIcon({
    title,
    colorHover,
    children,
    onClick,
    isDelete,
}: {
    title?: string;
    colorHover: string;
    children: React.ReactElement;
    isDelete?: boolean;
    onClick?(): void;
}) {
    const TooltipComponent = isDelete ? DangerTooltip : Tooltip;

    return (
        <TooltipComponent title={isDelete ? 'Delete' : title} placement="top">
            <IconButton
                onClick={onClick}
                sx={{
                    width: 32,
                    height: 32,
                    background: '#EEEEEE',
                    borderRadius: 5,
                    cursor: 'pointer',
                    color: '#737378',
                    transition: '.5s',
                    p: 0,
                    '&:hover': {
                        stroke: colorHover,
                        color: colorHover,
                    },
                    border: '4px solid #FAFAFA',
                }}
            >
                {children}
            </IconButton>
        </TooltipComponent>
    );
}
