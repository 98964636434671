import { logoHeader } from '@configs/ImageFromEnv';
import { Box } from '@mui/material';

export default function LogoIconWithName() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 'none',
                order: '0',
            }}
        >
            <Box
                component="img"
                src={logoHeader}
                sx={{
                    width: { xs: '180px', sm: '300px' },
                }}
            />
        </Box>
    );
}
