import { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function MarkerCameraIcon(props: Props) {
    return (
        <svg width="26" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#007BFF" />
            <path
                d="M16.354 21.6134H12.1407C10.034 21.6134 9.33398 20.2134 9.33398 18.8067V13.1934C9.33398 11.0867 10.034 10.3867 12.1407 10.3867H16.354C18.4607 10.3867 19.1607 11.0867 19.1607 13.1934V18.8067C19.1607 20.9134 18.454 21.6134 16.354 21.6134Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.0135 19.4001L19.1602 18.1001V13.8934L21.0135 12.5934C21.9202 11.9601 22.6668 12.3468 22.6668 13.4601V18.5401C22.6668 19.6534 21.9202 20.0401 21.0135 19.4001Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.666 15.332C16.2183 15.332 16.666 14.8843 16.666 14.332C16.666 13.7797 16.2183 13.332 15.666 13.332C15.1137 13.332 14.666 13.7797 14.666 14.332C14.666 14.8843 15.1137 15.332 15.666 15.332Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
