import color from '@theme/Colors';
import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

function ZoneDashboardIcon(props: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.07707 8.92308C7.75646 8.92308 8.30784 9.47446 8.30784 10.1538V18.7692C8.30784 19.4486 7.75646 20 7.07707 20H4.61553C3.93615 20 3.38477 19.4486 3.38477 18.7692V10.1538C3.38477 9.47446 3.93615 8.92308 4.61553 8.92308H7.07707ZM19.3848 12.6154C20.0642 12.6154 20.6155 13.1668 20.6155 13.8462V18.7692C20.6155 19.4486 20.0642 20 19.3848 20H16.9232C16.2438 20 15.6925 19.4486 15.6925 18.7692V13.8462C15.6925 13.1668 16.2438 12.6154 16.9232 12.6154H19.3848ZM13.2309 4C13.9103 4 14.4617 4.55138 14.4617 5.23077V18.7692C14.4617 19.4486 13.9103 20 13.2309 20H10.7694C10.09 20 9.53861 19.4486 9.53861 18.7692V5.23077C9.53861 4.55138 10.09 4 10.7694 4H13.2309Z"
                fill="currentColor"
            ></path>
        </svg>
    );
}

export default ZoneDashboardIcon;
