import { Paging } from '@Core';
import { CameraType } from '@EcamModel/controllers/ICameraHttpController';
import { CameraPhotos, GeoZoneCoordinates, GeoZones, GeoZoneType } from '@EcamModel/model';
import ChangeTypePhotoGeoZone from '@assets/jsx-icon/ChangeTypePhotoGeoZone';
import PopUpWarning from '@components/PopUpWarning';
import useMedia from '@hooks/useMedia';
import usePopUp from '@hooks/usePopUp';
import { Autorenew } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Divider,
    IconButton,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { getCenterFrame } from '@pages/cameras/details/utils';
import { useCameraDetailContext } from '@pages/cameras/hook/CameraDetailsProvider';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import color from '@theme/Colors';
import Konva from 'konva';
import { isNil } from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Circle, Image, Layer, Line, Stage, Text } from 'react-konva';
import { formatDateByTimeZone } from 'src/helpers';
import { useBackdrop } from 'src/providers/BackdropProvider';
import TextBgBackdrop from './TextBgBackdrop';

type Props = {
    prevGeoZone?: GeoZoneCoordinates[][];
    latestBlobPhoto?: CameraPhotos[] | undefined;
    originalBlobPhoto?: CameraPhotos;
    captureAt?: Date;
    timeZone?: string;
    notAvailable?: boolean;
    isHideAction?: boolean;
    cameraType?: CameraType;
    onRefresh?(): Promise<any>;
    onSetOriginalPhoto?: () => Promise<void>;
};

export default function WrapHistoryPhoto(props: Props) {
    const backdrop = useBackdrop();

    const { isHideAction = false } = props;

    const { notAvailable, handleRefreshImage } = useCameraDetailContext();

    const openBackdrop = () => {
        const message = <TextBgBackdrop text="Please wait for the camera to take a picture..." />;
        backdrop.setTrue(message);
    };

    return (
        <Stack>
            <Box borderRadius={2} bgcolor={color.white}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                    spacing={1}
                    p={2}
                    sx={{
                        borderRadius: 2,
                        height: 50,
                    }}
                >
                    <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                        <Typography color={color.darkPrimary} variant="h5" width={'100%'} mb={1}>
                            Photo
                        </Typography>
                    </Stack>
                    {!isHideAction && (
                        <Stack>
                            <Tooltip title="Refresh latest photo" placement="top" arrow>
                                <IconButton
                                    sx={{
                                        borderRadius: '3px',
                                        width: '24px',
                                        height: '24px',
                                        color: 'white',
                                        backgroundColor: '#2E7D32',
                                        ':hover': {
                                            backgroundColor: '#256428',
                                        },
                                        '&.MuiIconButton-root.Mui-disabled': {
                                            backgroundColor: '#eee',
                                        },
                                        '&:hover .rotate-icon': {
                                            transform: 'rotate(90deg) scale(1.1)',
                                        },
                                    }}
                                    onClick={() => {
                                        openBackdrop();
                                        handleRefreshImage().finally(() => {
                                            backdrop.setFalse();
                                        });
                                    }}
                                    disabled={notAvailable || backdrop.open}
                                >
                                    <Autorenew
                                        sx={{ fontSize: 17, transition: 'transform 0.3s ease-in-out' }}
                                        className="rotate-icon"
                                    />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )}
                </Stack>

                <Divider
                    orientation="vertical"
                    sx={{
                        borderBottomWidth: 1,
                        borderColor: color.grey300,
                        mx: 2,
                    }}
                />

                <Stack px={2} pb={2}>
                    <HistoriesPhoto {...props} />
                </Stack>
            </Box>
        </Stack>
    );
}

interface PreviewPhoto {
    previewOriginal: boolean;
    previewLasted: boolean;
}

function HistoriesPhoto(props: Props) {
    const stageRef = useRef<Konva.Stage>(null);
    const {
        imageLatest,
        imageOriginal,
        scaleNotFullLatestCameraSolar,
        scaleFullLatestCameraSolar,
        scaleNotFullOriginalCameraSolar,
        scaleFullOriginalCameraSolar,
        scaledPrevGeoZoneCameraSolar,
        prevGeoZonesCameraSolar,
        blobLatestPhoto,
        blobOriginalPhoto,
        containerRef,
        containerSize,
        handleSetOriginalPhoto,
    } = useCameraDetailContext();

    const { isMediumMD, isMobileSM } = useMedia()

    const [openPreviewPhoto, setOpenPreviewPhoto] = useState<PreviewPhoto>({
        previewLasted: false,
        previewOriginal: false,
    });

    const setPartialOpenPreviewPhoto = (p: Partial<PreviewPhoto>) => {
        setOpenPreviewPhoto((prev) => ({ ...prev, ...p }));
    };

    return (
        <Stack direction={isMediumMD ? 'column' : 'row'} spacing={2} width={'100%'}>
            {!blobOriginalPhoto ? (
                <Stack width={'100%'}>
                    <NoDataCameras
                        sx={{
                            border: '1px solid #DDDDDD',
                            minHeight: '400px',
                            mt: 2,
                        }}
                    />
                </Stack>
            ) : !imageOriginal ? (
                <Stack width="100%">
                    <Skeleton
                        sx={{ borderRadius: '2px', mt: 2, bgcolor: color.grey200, minHeight: '400px' }}
                        variant="rectangular"
                    />
                </Stack>
            ) : (
                <ImagePreviewWithGeoZone
                    stageRef={stageRef}
                    containerRef={containerRef}
                    containerSize={containerSize}
                    fullScale={scaleFullOriginalCameraSolar}
                    scaleNotFull={scaleNotFullOriginalCameraSolar}
                    scaledGeoZones={scaledPrevGeoZoneCameraSolar!}
                    prevGeoZones={prevGeoZonesCameraSolar!}
                    openPreviewPhoto={openPreviewPhoto}
                    image={imageOriginal!}
                    heading="Original photo"
                    previewKey="previewOriginal"
                    cameraType={props.cameraType}
                    timeZone={props.timeZone!}
                    captureAt={props.originalBlobPhoto?.CaptureAt}
                    onSetPartialOpenPreviewPhoto={setPartialOpenPreviewPhoto}
                    onClickPreview={() => setPartialOpenPreviewPhoto({ previewOriginal: true })}
                />
            )}

            {!blobLatestPhoto ? (
                <Stack width={'100%'}>
                    <NoDataCameras
                        sx={{
                            border: '1px solid #DDDDDD',
                            minHeight: '400px',
                            mt: 2,
                        }}
                    />
                </Stack>
            ) : !imageLatest ? (
                <Stack width="100%">
                    <Skeleton
                        sx={{ borderRadius: '2px', mt: 2, bgcolor: color.grey200, minHeight: '400px' }}
                        variant="rectangular"
                    />
                </Stack>
            ) : (
                <ImagePreviewWithGeoZone
                    stageRef={stageRef}
                    containerRef={containerRef}
                    containerSize={containerSize}
                    fullScale={scaleFullLatestCameraSolar}
                    scaleNotFull={scaleNotFullLatestCameraSolar}
                    scaledGeoZones={scaledPrevGeoZoneCameraSolar!}
                    prevGeoZones={prevGeoZonesCameraSolar!}
                    openPreviewPhoto={openPreviewPhoto}
                    image={imageLatest!}
                    heading="Latest photo"
                    previewKey="previewLasted"
                    cameraType={props.cameraType}
                    timeZone={props.timeZone!}
                    captureAt={props.captureAt}
                    onSetPartialOpenPreviewPhoto={setPartialOpenPreviewPhoto}
                    onSetOriginalPhoto={handleSetOriginalPhoto}
                    onClickPreview={() => setPartialOpenPreviewPhoto({ previewLasted: true })}
                />
            )}
        </Stack>
    );
}

export const BaseBoxDecoration = ({
    heading,
    date,
    timeZone,
    previewKey,
    onSetOriginalPhoto,
}: {
    heading?: string;
    date: Date;
    timeZone?: string;
    previewKey?: keyof PreviewPhoto;
    onSetOriginalPhoto?: () => Promise<void>;
}) => {
    const popUpWarningChangeOriginalPhoto = usePopUp();

    return (
        <>
            <Stack
                width={'100%'}
                sx={{
                    p: '12px',
                    background: 'rgba(0, 0, 0, 0.5)',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                    color: '#fff',
                    zIndex: 1,
                }}
            >
                {heading}
                {previewKey === 'previewLasted' && (
                    <Box position={'absolute'} top={'13%'} right={'2%'}>
                        <Tooltip title="Change to original photo" placement="top" arrow>
                            <Button
                                variant="cancelSmall"
                                sx={{
                                    borderRadius: '50%',
                                    p: '4px',
                                    minWidth: '24px',
                                    bgcolor: 'transparent',
                                    border: `2px solid ${'#DDDDDD'}`,
                                    ':hover': {
                                        bgcolor: 'transparent',
                                        transition: '0.3s ease-in-out',
                                    },
                                }}
                                onClick={() => {
                                    popUpWarningChangeOriginalPhoto.setTrue();
                                }}
                            >
                                <ChangeTypePhotoGeoZone />
                            </Button>
                        </Tooltip>
                    </Box>
                )}
            </Stack>
            <Stack
                sx={{
                    py: 1,
                    px: 2,
                    background: 'rgba(0, 0, 0, 0.5)',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    textAlign: 'center',
                    color: '#fff',
                }}
            >
                {formatDateByTimeZone(date, timeZone ?? undefined, true)}
            </Stack>

            <PopUpWarning
                {...popUpWarningChangeOriginalPhoto}
                title="Confirm"
                message="Are you sure you want to set this latest photo as the original photo?"
                onConfirm={() => {
                    onSetOriginalPhoto?.().finally(() => {
                        popUpWarningChangeOriginalPhoto.onClose?.();
                    });
                }}
            />
        </>
    );
};

export const PreviewImageWithGeoZone = ({
    open,
    onClose,
    imgSrc,
    scaleX,
    scaleY,
    geoZones,
    cameraType,
    listGeoZonesByView,
}: {
    open: boolean;
    onClose?(): void;
    imgSrc: HTMLImageElement | undefined;
    scaleX?: number;
    scaleY?: number;
    geoZones?: {
        x: number;
        y: number;
        geoZoneId: number | undefined;
    }[][];
    cameraType?: CameraType;
    listGeoZonesByView?: Paging<GeoZones>;
}) => {
    const stageRef = useRef<Konva.Stage>(null);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose?.();
            }}
            PaperProps={{
                sx: {
                    minWidth: 1280,
                    minHeight: 720,
                },
            }}
        >
            <div style={{ width: 1280, height: 720 }}>
                {!imgSrc ? (
                    <Stack direction="row" width="100%" justifyContent="center" alignItems="center" minHeight={720}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <Stage ref={stageRef} width={1280} height={720}>
                        <Layer>
                            <Image image={imgSrc} scaleX={scaleX} scaleY={scaleY} />
                            {!!geoZones?.length &&
                                geoZones?.map((frame, frameIndex) => {
                                    if (!frame || !frame.length) {
                                        return null;
                                    }

                                    const center = getCenterFrame(frame);
                                    const firstPoint = frame[0];

                                    const matchingGeoZone = listGeoZonesByView?.rows.find(
                                        (zone) => zone.Id === frame[0].geoZoneId
                                    );
                                    const strokeColor =
                                        matchingGeoZone?.GeoZoneType === GeoZoneType.Detail ? 'blue' : color.primary;

                                    return (
                                        <React.Fragment key={`frame-${frameIndex}`}>
                                            <Line
                                                points={frame.flatMap((point) => [point.x, point.y])}
                                                stroke={
                                                    isNil(firstPoint?.geoZoneId)
                                                        ? 'red'
                                                        : cameraType === CameraType.PTZ
                                                            ? strokeColor
                                                            : 'blue'
                                                }
                                                strokeWidth={3}
                                            />
                                            {!isNil(firstPoint?.geoZoneId) && (
                                                <>
                                                    <Circle x={center.x} y={center.y} radius={15} fill={color.white} />
                                                    <Text
                                                        x={center.x}
                                                        y={center.y}
                                                        text={String(`${frameIndex + 1}`)}
                                                        fontSize={14}
                                                        fill={color.textPrimary}
                                                        align={'center'}
                                                        verticalAlign={'middle'}
                                                        offsetX={4}
                                                        offsetY={6}
                                                    />
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </Layer>
                    </Stage>
                )}
            </div>
        </Dialog>
    );
};

interface ImagePreviewWithGeoZoneProps {
    image: HTMLImageElement;
    scaleNotFull: number;
    containerRef: any;
    fullScale: number;
    stageRef: React.RefObject<Konva.Stage>;
    scaledGeoZones: {
        x: number;
        y: number;
        geoZoneId: number | undefined;
    }[][];
    prevGeoZones: {
        x: number;
        y: number;
        geoZoneId: number | undefined;
    }[][];
    containerSize: { width: number; height: number };
    previewKey: keyof PreviewPhoto;
    openPreviewPhoto: PreviewPhoto;
    heading: string;
    timeZone: string;
    captureAt?: Date;
    cameraType?: CameraType;
    onSetPartialOpenPreviewPhoto: (p: Partial<PreviewPhoto>) => void;
    onClickPreview: () => void;
    onSetOriginalPhoto?: () => Promise<void>;
}

const ImagePreviewWithGeoZone: React.FC<ImagePreviewWithGeoZoneProps> = ({
    image,
    stageRef,
    scaleNotFull,
    containerRef,
    fullScale,
    scaledGeoZones,
    prevGeoZones,
    containerSize,
    previewKey,
    openPreviewPhoto,
    heading,
    timeZone,
    captureAt,
    cameraType,
    onSetPartialOpenPreviewPhoto,
    onClickPreview,
    onSetOriginalPhoto,
}) => {
    const { isMediumMD, isMobileSM } = useMedia()

    return (
        <Stack width={isMediumMD ? '100%' : '50%'}>
            <div
                ref={containerRef}
                style={{
                    width: '100%',
                    height: containerSize.height,
                    margin: '10px auto 0',
                    position: 'relative',
                }}
            >
                <Stage
                    ref={stageRef}
                    width={containerSize.width}
                    height={containerSize.height}
                    style={{ cursor: 'pointer' }}
                >
                    <Layer>
                        <Image image={image} scaleX={scaleNotFull} scaleY={scaleNotFull} onClick={onClickPreview} />
                        {!!scaledGeoZones.length &&
                            scaledGeoZones.map((frame, frameIndex) => {
                                const center = getCenterFrame(frame);
                                return (
                                    <React.Fragment key={`frame-${frameIndex}`}>
                                        <Line points={frame.flatMap((point) => [point.x, point.y])} stroke="blue" />
                                        <Circle x={center.x} y={center.y} radius={15} fill="#fff" />
                                        <Text
                                            x={center.x}
                                            y={center.y}
                                            text={`${frameIndex + 1}`}
                                            fontSize={14}
                                            fill="black"
                                            align="center"
                                            verticalAlign="middle"
                                            offsetX={4}
                                            offsetY={6}
                                        />
                                    </React.Fragment>
                                );
                            })}
                    </Layer>
                </Stage>
                <BaseBoxDecoration
                    timeZone={timeZone}
                    heading={heading}
                    date={captureAt ?? moment().toDate()}
                    previewKey={previewKey}
                    onSetOriginalPhoto={onSetOriginalPhoto}
                />
                <PreviewImageWithGeoZone
                    open={openPreviewPhoto[previewKey]}
                    onClose={() => onSetPartialOpenPreviewPhoto({ [previewKey]: false })}
                    imgSrc={image}
                    scaleX={fullScale}
                    scaleY={fullScale}
                    geoZones={prevGeoZones}
                    cameraType={cameraType}
                />
            </div>
        </Stack>
    );
};
