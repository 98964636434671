import { Location, LocationSMStatus } from '@EcamModel/model';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { LocationStatusReadableMapping } from '@pages/cameras/details/components/CameraInformation';
import { BaseItem } from '@pages/cameras/list/styles';
import color from '@theme/Colors';
import { useNavigate } from 'react-router-dom';

type Props = {
    locationItem: Location;
    MAC?: string;
};

export type PriorityColor = {
    key: number;
    bgColorPriority: string;
};

export default function LocationItems(props: Props) {
    const navigate = useNavigate();
    const { locationItem, MAC } = props;

    const getStatusColor = (status: LocationSMStatus) => {
        switch (status) {
            case LocationSMStatus.Prospective:
                return { colorText: color.secondary, bgColor: '#E5F2FF' };
            case LocationSMStatus.Live:
                return { colorText: '#009D4F', bgColor: '#E5F5ED' };
            case LocationSMStatus.Transferred:
                return { colorText: '#CC0066', bgColor: '#CC00661A' };
            case LocationSMStatus.Decommissioned:
                return { colorText: '#E01B00', bgColor: '#FCE8E5' };
            case LocationSMStatus.OnHold:
                return { colorText: '#FFB600', bgColor: '#FFF8E5' };
            default:
                return { colorText: '#E01B00', bgColor: '#FCE8E5' };
        }
    };
    const { colorText, bgColor } = getStatusColor(locationItem.LocationStatus!);

    // hàm trong 1 mảng sẽ thêm được dấu ", "
    const nameZone: string[] = [];
    locationItem.Zones?.forEach((row) => {
        nameZone.push(row.PublicName);
    });
    const combinedPublicNames = nameZone.join(', ');

    const addresses = [locationItem?.Address1, locationItem?.Address2, locationItem?.Address3].filter(Boolean);

    const priorityColors: Record<number, PriorityColor> = {
        1: {
            key: 1,
            bgColorPriority: '#F8D7DA',
        },
        2: {
            key: 2,
            bgColorPriority: '#FFF3CD',
        },
        3: {
            key: 3,
            bgColorPriority: '#CCE5FF',
        },
        4: {
            key: 4,
            bgColorPriority: '#E2E3E5',
        },
        5: {
            key: 5,
            bgColorPriority: '#E5F2FF',
        },
    };

    const getPriorityLocationsColor = (status: number) => {
        return (
            priorityColors[status] ?? {
                key: 0,
                bgColorPriority: '#FCE8E5',
            }
        );
    };

    const { bgColorPriority } = getPriorityLocationsColor(locationItem?.Priority);

    return (
        <BaseItem component={Stack} sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                {locationItem?.Priority !== null && (
                    <Typography
                        padding={'4px 8px'}
                        variant="body2"
                        borderRadius={'5px'}
                        sx={{ cursor: 'default', color: color.textSecondary, bgcolor: bgColorPriority, fontSize: 12 }}
                    >
                        {`P${locationItem?.Priority}`}
                    </Typography>
                )}
                <Typography
                    variant="h5"
                    onClick={() => {
                        navigate(`/cameras${MAC && `?searchType=2&searchBy=${MAC}`}`);
                    }}
                    sx={{ cursor: 'pointer', '&:hover': { color: color.priText } }}
                >
                    {locationItem.Name}
                </Typography>

                <Typography
                    variant="body1"
                    color={colorText}
                    sx={{
                        padding: '4px 8px',
                        fontSize: 12,
                        background: bgColor,
                        borderRadius: '5px',
                    }}
                >
                    {LocationStatusReadableMapping[locationItem.LocationStatus!] ?? 'N/A'}
                </Typography>
            </Stack>
            {/* <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant="body1" color={color.secondary}>
                    Camera: {locationItem?.CameraCount ?? 'N/A'}
                </Typography>
                {combinedPublicNames.length > 0 && (
                    <>
                        <Divider orientation="vertical" flexItem color={'#85858A'} sx={{ display: 'flex' }} />
                        <Typography variant="body1" color={'#85858A'}>
                            Address: {combinedPublicNames}
                        </Typography>
                    </>
                )}
                {locationItem.Address1 !== null || locationItem.Address2 !== null || locationItem.Address3 !== null ? (
                    <>
                        <Divider orientation="vertical" flexItem color={'#85858A'} sx={{ display: 'flex' }} />
                        <Typography variant="body1" color={'#85858A'}>
                            Address: {`${addresses.join(', ')}`}
                        </Typography>
                    </>
                ) : (
                    <></>
                )}
            </Stack> */}
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant="body1" color={color.secondary}>
                    Camera Solar: {locationItem?.Solar4gCameraCount ?? 0}
                </Typography>
                <Divider orientation="vertical" flexItem color={'#85858A'} sx={{ display: 'flex' }} />
                <Typography variant="body1" color={color.secondary}>
                    Camera PTZ: {locationItem?.PTZCameraCount ?? 0}
                </Typography>
            </Stack>
            {locationItem.Address1 !== null || locationItem.Address2 !== null || locationItem.Address3 !== null ? (
                <Typography variant="body1" color={'#85858A'}>
                    Address: {`${addresses.join(', ')}`}
                </Typography>
            ) : (
                <></>
            )}
        </BaseItem>
    );
}
