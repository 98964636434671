import BaseListRender from '@components/BaseListRender';
import FilterRoot from '@components/filter/FilterRoot';
import StyledAutocomplete from '@components/styled-autocomplete';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushError } from '@components/toast';
import { locationController } from '@controllers/index';
import { defaultPagination, Paging } from '@Core';
import { IGetLocationProps } from '@EcamModel/controllers/ILocationHttpController';
import { Location, LocationSMStatus } from '@EcamModel/model';
import useScrollToTop from '@hooks/useScrollToTop';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { BaseSelect, Filter } from '@pages/cameras/list';
import { search } from '@pages/cameras/list/helpers';
import color from '@theme/Colors';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationItems from './components/LocationItems';
import { FiSearch } from 'react-icons/fi';

export const locationAutocompleteStatus: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Decommissioned',
        Value: LocationSMStatus.Decommissioned,
    },
    {
        Id: 2,
        Name: 'Live',
        Value: LocationSMStatus.Live,
    },
    {
        Id: 3,
        Name: 'On hold',
        Value: LocationSMStatus.OnHold,
    },
    {
        Id: 4,
        Name: 'Prospective',
        Value: LocationSMStatus.Prospective,
    },
    {
        Id: 5,
        Name: 'Transferred',
        Value: LocationSMStatus.Transferred,
    },
];

export const filteredAndSortedOptionsPriorities: BaseSelect[] = [
    {
        Id: 1,
        Name: 'P0',
        Value: 0,
    },
    {
        Id: 2,
        Name: 'P1',
        Value: 1,
    },
    {
        Id: 3,
        Name: 'P2',
        Value: 2,
    },
    {
        Id: 4,
        Name: 'P3',
        Value: 3,
    },
    {
        Id: 5,
        Name: 'P4',
        Value: 4,
    },
    {
        Id: 6,
        Name: 'P5',
        Value: 5,
    },
];

export default function Locations() {
    useScrollToTop();
    const navigate = useNavigate();
    const [pagingLocation, setPagingLocation] = useState<Paging<Location>>(defaultPagination());
    // const [originalData, setOriginalData] = useState<Location[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchValue, setSearchValue] = useState('');

    const [filter, setFilter] = useState<Filter>({
        page: 1,
        // locationStatus: {
        //     Id: 2,
        //     Name: 'Live',
        //     Value: LocationSMStatus.Live,
        // },
    });

    const locationStatusAutocomplete = useStyledAutocomplete({
        list: {
            options: locationAutocompleteStatus,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const handleResetFilter = () => {
        setSearchValue('');
        setFilter({} as Filter);
    };

    const getListLocations = async () => {
        setIsLoading(true);
        await locationController
            .listHasLocation({
                pageSize: 10,
                page: filter.page || 1,
                filter: {
                    Id: filter.location?.Id ?? undefined,
                    LocationStatus: filter.locationStatus?.Value ?? undefined,
                    Priority: filter.locationPriorities?.Value ?? undefined,
                },
                Mac: filter.search || undefined,
                sorts: ['Name'],
            })
            .then((res) => {
                setPagingLocation(res);
                // if (originalData.length === 0) {
                //     setOriginalData(res.rows);
                // }
            })
            .catch((err) => {
                // pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const locationAutocomplete = useStyledAutocomplete({
        async getList(props: IGetLocationProps): Promise<Paging<any>> {
            const res = await locationController.listHasCamera(props).finally(() => {});
            return res;
        },
        listProps: {
            search: { fields: ['name'] } as any,
        },
    });

    const onChangeLocation = (l?: Location) => {
        setFilter((prev) => ({ ...prev, location: l, zones: undefined, page: 1 }));
    };

    const debounceOnChangeSearch = useCallback(
        _.debounce((value) => searchByMAC(value), 1000),
        []
    );

    const searchByMAC = (value: string) => {
        setFilter((prev) => ({ ...prev, search: value, page: 1 }));
    };

    useEffect(() => {
        getListLocations();
    }, [filter.locationStatus, filter.page, filter.location, filter.locationPriorities, filter.search]);

    // const filteredData = _.filter(originalData, (p) => !_.isNull(p.Priority));
    // const groupedData = _.groupBy(filteredData, 'Priority');

    // const filteredAndSortedOptions = Object.keys(groupedData).map((priority, index) => ({
    //     Id: index,
    //     Name: `P${priority}`,
    //     Value: priority,
    // }));

    const priorityStatusAutocomplete = useStyledAutocomplete({
        list: {
            options: filteredAndSortedOptionsPriorities,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
        // dependencyList: [originalData],
    });

    return (
        <Stack direction={'column'}>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="h3">Camera location list</Typography>
                <Button variant="contained" sx={{ minWidth: '150px' }} onClick={() => navigate(`cameras/add`)}>
                    Add camera
                </Button>
            </Stack>
            <Stack mt={2}>
                <FilterRoot
                    handleResetFilter={handleResetFilter}
                    filterChild={
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <StyledAutocomplete
                                    {...locationAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Location name"
                                    value={filter.location}
                                    placeholder="All locations name"
                                    onChange={(l) => {
                                        onChangeLocation(l);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledAutocomplete
                                    {...priorityStatusAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Location priority"
                                    value={filter.locationPriorities}
                                    placeholder="All location priorities"
                                    onChange={(p) => {
                                        setFilter((prev) => ({ ...prev, locationPriorities: p, page: 1 }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledAutocomplete
                                    {...locationStatusAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Location status"
                                    value={filter.locationStatus}
                                    placeholder="All location status"
                                    onChange={(l) => {
                                        setFilter((prev) => ({ ...prev, locationStatus: l, page: 1 }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Search"
                                    placeholder="Search by mac"
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        debounceOnChangeSearch(e.target.value);
                                    }}
                                    value={searchValue}
                                    InputProps={{
                                        endAdornment: (
                                            <FiSearch
                                                color="disabled"
                                                size={16}
                                                style={{ color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' }}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                />
            </Stack>
            <Stack mt={3}>
                <Typography textAlign={'right'} sx={{ color: color.priText }}>
                    Total: {pagingLocation.total ?? 0}
                </Typography>
            </Stack>
            <BaseListRender
                styleContainer={{ mt: 2, mb: 5 }}
                styleList={{ gap: 2 }}
                paging={pagingLocation}
                renderedItem={(location) => <LocationItems locationItem={location} MAC={searchValue} />}
                isLoading={isLoading}
                setPage={(page) => {
                    setFilter((prev) => ({ ...prev, page: page }));
                }}
            />
        </Stack>
    );
}
