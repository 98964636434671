// src/components/BaseTabs.tsx
import React from 'react';
import { Box, Tabs, Tab, SxProps } from '@mui/material';
import color from '@theme/Colors';

export interface TabConfig {
    index: number;
    label: string;
    icon?: React.ReactElement;
    sx?: SxProps;
    onClick?: () => void;
}

interface BaseTabsProps {
    tabs: TabConfig[];
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
    sx?: SxProps;
    minWidth?: number | string;
}

const BaseEnhancedTabs: React.FC<BaseTabsProps> = (props) => {
    const { tabs, value, onChange, sx, minWidth } = props;

    return (
        <Box sx={{ borderColor: 'divider', ...sx }}>
            <Tabs
                value={value}
                onChange={onChange}
                variant='scrollable'
                scrollButtons={false}
                sx={{
                    '& .MuiButtonBase-root': {
                        minWidth: minWidth ?? undefined,
                        px: 1.5,
                        fontSize: 14,
                        background: '#F5F5F5',
                        color: color.grey600,
                    },
                    '& .MuiTabs-indicator': {
                        background: color.success,
                    },

                    '& .Mui-selected': {
                        color: `${color.success} !important`,

                        '&.MuiTab-root': {
                            background: color.lightPrimary,
                        },
                    },
                    '& .base-Tab-root': {
                        marginTop: '0 !important',
                    },
                    '&.MuiTabs-root': {
                        minHeight: 'auto',
                    },
                }}
            >
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} icon={tab.icon} onClick={tab.onClick} sx={tab.sx} />
                ))}
            </Tabs>
        </Box>
    );
};

export default BaseEnhancedTabs;
