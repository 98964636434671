import { BaseHttpController } from '@Core/controller/BaseHttpController';
import {
    PotentialContraventionLookUp,
    PotentialContraventions,
    PotentialContraventionWithInfo,
    StatisticTotal,
} from '@EcamModel/model';
import { AxiosInstance } from 'axios';
import {
    IGetPotentialContraventionIds,
    IGetPotentialContraventionProps,
    IPotentialContraventionHttpController,
} from '../IPotentialContraventionHttpController';

export class PotentialContraventionHttpController
    extends BaseHttpController<PotentialContraventions>
    implements IPotentialContraventionHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'potentialContravention', client);
    }

    async lookUp(
        plate: string,
        props?: { GroupPotentialContraventionId: number }
    ): Promise<PotentialContraventionLookUp> {
        return await this.doPost({ path: `look-up/${plate}`, body: props }).then((res) => res.data);
    }

    async getPotentialContraventionIds(props: IGetPotentialContraventionIds): Promise<number[]> {
        return await this.doPost({ path: `get-ids`, body: props }).then((res) => res.data);
    }

    async statisticTotal(props: IGetPotentialContraventionProps): Promise<StatisticTotal> {
        return await this.doPost({ path: `statistic-total`, body: props }).then((res) => res.data);
    }

    async get(id: string): Promise<PotentialContraventionWithInfo> {
        return (await super.get(id)) as PotentialContraventionWithInfo;
    }
}
