import React, { useContext } from 'react';
import { DrawerContext } from './useDrawer';
import { useNavigate } from 'react-router-dom';
import usePopUp from '@hooks/usePopUp';
import { useMsal } from '@azure/msal-react';
import { appConfig } from '@configs/index';
import { Avatar, Box, Drawer, Grid, List, Stack, Tooltip } from '@mui/material';
import color from '@theme/Colors';
import { DrawerHeader } from './MyDrawer';
import LanguageSelect from '@components/LanguageSelect';
import { FiArrowLeft } from 'react-icons/fi';
import ItemDrawer from './DrawerItem';
import LogoutIcon from '@assets/drawer-icon/LogoutIcon';
import i18n from 'src/translations/config';
import PopUpWarning from '@components/PopUpWarning';

export default function DrawerSmall() {
    const { instance, accounts } = useMsal();
    const { t } = i18n;
    const {
        openDrawerSmall,
        setOpenDrawerSmall,
        ListDrawItem,
        activeItem,
        setActiveItem,
        isMobileSM,
        isMediumMD,
        setIsShowDrawer,
    } = useContext(DrawerContext);
    let navigate = useNavigate();

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        if (isMobileSM) {
            setIsShowDrawer(false);
        } else if (isMediumMD) {
            setIsShowDrawer(true);
        }

        setOpenDrawerSmall(open);
    };

    const popUpWarning = usePopUp({
        onConfirm() {
            const currentAccount = instance.getAccountByHomeId(accounts[0].homeAccountId);
            console.log(currentAccount?.homeAccountId);
            const logoutRequest = {
                account: currentAccount,
                postLogoutRedirectUri: '/',
            };
            instance.logoutRedirect(logoutRequest);
        },
    });
    const isShowMultiLanguage = appConfig.flagFeatures.multiLanguage;

    return (
        <React.Fragment key={1}>
            <PopUpWarning {...popUpWarning} message="Are you sure you want to log out?" />

            <Drawer
                anchor={'left'}
                open={openDrawerSmall}
                onClose={toggleDrawer(false)}
                sx={{
                    pt: 5,
                    '& .MuiPaper-root': {
                        background: color.grey100,
                        pt: 8,
                        px: 1,
                        overflow: 'visible',
                    },
                }}
            >
                <Box sx={{ width: 220 }} role="presentation">
                    <DrawerHeader sx={{ padding: 0, justifyContent: 'center' }} className="drawer-header">
                        <Stack direction={'row'}>
                            {isShowMultiLanguage && (
                                <Stack sx={{ display: 'block' }}>
                                    <LanguageSelect isShowLabel={true} />
                                </Stack>
                            )}

                            <Avatar
                                sx={[
                                    {
                                        background: color.grey100,
                                        color: color.grey600,
                                        display: 'flex',
                                        width: '30px',
                                        height: '30px',
                                        position: 'absolute',
                                        boxShadow: '0 0 3px 2px rgba(0, 0, 0, 0.2) !important',
                                        zIndex: 9,
                                        right: '-15px',
                                    },
                                ]}
                                onClick={toggleDrawer(false)}
                            >
                                <FiArrowLeft style={{ opacity: 1, cursor: 'pointer' }} />
                            </Avatar>
                        </Stack>
                    </DrawerHeader>

                    <List
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Grid container height={'100%'} justifyContent={'space-between'}>
                            <Grid item xs={12}>
                                {ListDrawItem.map((item, index) => {
                                    const isActive = item.id === activeItem;

                                    return (
                                        <Tooltip key={index} title={item.label} placement="right">
                                            <React.Fragment>
                                                <ItemDrawer
                                                    key={index}
                                                    icon={item.icon}
                                                    isActive={isActive}
                                                    label={item.label}
                                                    open={openDrawerSmall}
                                                    isSmallDrawer={true}
                                                    onClick={() => {
                                                        setActiveItem(item.id);
                                                        navigate(item.path);
                                                        setOpenDrawerSmall(false);
                                                        setIsShowDrawer(false);
                                                    }}
                                                />
                                            </React.Fragment>
                                        </Tooltip>
                                    );
                                })}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'space-between'}
                            >
                                <ItemDrawer
                                    key={99}
                                    icon={<LogoutIcon className="svg-item-Menu" />}
                                    isActive={false}
                                    label={t('drawer.logout')}
                                    open={openDrawerSmall}
                                    isSmallDrawer={true}
                                    onClick={popUpWarning.setTrue}
                                />
                            </Grid>
                        </Grid>
                    </List>
                </Box>
            </Drawer>
        </React.Fragment>
    );
}
