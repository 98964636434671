import { Box, ClickAwayListener, Fade, IconButton, TextField } from '@mui/material';
import { StaticTimePicker, StaticTimePickerProps } from '@mui/x-date-pickers';
import { omit } from 'lodash';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
type Props = {
    onChange?(value: Date): void;
    value: Date;
    timePickerProps?: StaticTimePickerProps<Moment>;
    width?: React.CSSProperties['width'];
    marginTop?: React.CSSProperties['marginTop'];
    left?: React.CSSProperties['left']
    zIndex?: React.CSSProperties['zIndex'];
};
export default function BaseTimePicker(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => setOpen(false)}>
            <Box sx={{ position: 'relative', zIndex: props.zIndex, width: `${props.width ?? '140px'} !important` }}>
                <TextField
                    value={!!props.value ? moment(props.value).format('HH:mm') : 'hh:mm'}
                    sx={{ width: '100%' }}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setOpen(true)}>
                                <AiOutlineClockCircle />
                            </IconButton>
                        ),
                        readOnly: true,
                    }}
                />
                <Fade in={!!open}>
                    <Box
                        sx={{
                            background: '#fff',
                            borderRadius: 1,
                            position: 'absolute',
                            top: props.marginTop,
                            border: '1px solid #DDDDDD',
                            left: props.left ?? 0
                        }}
                    >
                        <StaticTimePicker
                            ampm={false}
                            value={(props.value as any) ?? undefined}
                            displayStaticWrapperAs="desktop"
                            onChange={(value, text) => {
                                props.onChange?.(value! as any);
                            }}
                            minutesStep={5}
                            onAccept={() => {
                                setOpen(false);
                            }}
                            {...omit(props.timePickerProps, 'onChange')}
                        />
                    </Box>
                </Fade>
            </Box>
        </ClickAwayListener>
    );
}
