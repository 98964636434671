import { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function MarkerLocationIcon(props: Props) {
    return (
        <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12.327v-.044C0 5.504 5.504 0 12.283 0c6.778 0 12.283 5.504 12.283 12.283v.044c-.028 9.179-8.861 14.657-11.653 15.632a2 2 0 0 1-.63.116c-.221 0-.434-.042-.63-.116C8.86 26.984.028 21.506 0 12.327M12.284 7.02a5.266 5.266 0 0 1 5.264 5.264 5.266 5.266 0 0 1-5.264 5.264 5.266 5.266 0 0 1-5.264-5.264 5.266 5.266 0 0 1 5.264-5.264"
                fill={props.fill ?? 'red'}
            />
        </svg>
    );
}
