import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ContraventionAuditEvent } from '@EcamModel/model/ContraventionAuditEvent';
import { AxiosInstance } from 'axios';
import {
    IContraventionEventHttpController,
    IListContraventionAuditEvent,
} from '../IContraventionAuditEventHttpController';

export class ContraventionAuditEventHttpController
    extends BaseHttpController<ContraventionAuditEvent>
    implements IContraventionEventHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'contravention-audit-event', client);
    }

    async list(props: IListContraventionAuditEvent): Promise<Paging<ContraventionAuditEvent>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }
}
