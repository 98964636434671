import { BaseModel } from '@Core';

export interface CameraNotes extends BaseModel {
    Notes: string;
    CameraId: number;
    Type: CameraNoteType;
}

export enum CameraNoteType {
    Verification,
    Camera,
}
