import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Stack, Typography } from '@mui/material';

type Props = IPopUp & {
    title?: string;
    message?: string;
    isLoading?: boolean;
};

export default function PopUpWarning(props: Props) {
    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'xs',
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Typography variant="h4" color="error">
                    {props.title || 'Warning'}
                </Typography>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            hideConfirm
            hideClose
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack mt={1} pb={0.5}>
                    <Typography textAlign={'center'}>{props.message || 'Are you sure to delete?'}</Typography>

                    {props.customActions ? (
                        <>{props.customActions}</>
                    ) : (
                        <Stack mt={3} direction={'row'} justifyContent="space-between">
                            <Button sx={{ minWidth: { sm: 130 } }} variant="cancel" onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button
                                sx={{ minWidth: { sm: 130 } }}
                                variant="errorOutlined"
                                onClick={props.onConfirm}
                                disabled={props.isLoading}
                            >
                                Confirm
                            </Button>
                        </Stack>
                    )}
                </Stack>
            }
        />
    );
}
