import CameraIcon from '@assets/drawer-icon/CameraIcon';
import CameraOverview from '@assets/drawer-icon/CameraOverview';
import DashboardIcon from '@assets/drawer-icon/DashboardIcon';
import MapOverviewIcon from '@assets/drawer-icon/MapOverviewIcon';
import VerificationIcon from '@assets/drawer-icon/VerificationIcon';
import React, { useEffect, useState } from 'react';
import { DashboardOutlined } from '@mui/icons-material';
import { CiInstagram } from 'react-icons/ci';
import { IoAnalyticsOutline } from 'react-icons/io5';
import i18n from 'src/translations/config';
import { IoIosTimer } from 'react-icons/io';
import LocationsIcon from '@assets/drawer-icon/LocationsIcon';
import { TbChartBubble } from 'react-icons/tb';
import { TbChartHistogram } from 'react-icons/tb';
import color from '@theme/Colors';
import TaskManagement from '@assets/drawer-icon/TaskManagement';
import useMedia from '@hooks/useMedia';
import ZoneDashboardIcon from '@assets/drawer-icon/ZoneDashboardIcon';

export interface DrawerItem {
    id: DrawerPage;
    label: string;
    icon: JSX.Element;
    path?: any;
}

export enum DrawerPage {
    Cameras,
    MapOverview,
    ZoneDashboard,
    Verification,
    OverviewVerification,
    OverviewCameras,
    Dashboard,
    Report,
    ReportHeatMap,
    CameraTime,
    ReportsAnalyzing,
    TaskManagement,
}

export default function useDrawer() {
    const { t } = i18n;
    // const { logout, account } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState<DrawerPage>(DrawerPage.Cameras);
    const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);
    const [openDrawerSmall, setOpenDrawerSmall] = useState(false);
    const { isMobileSM, isMediumMD } = useMedia();

    const handleDrawerClose = () => {
        if (isMediumMD) {
            setOpenDrawerSmall(true);
            setIsShowDrawer(true);
        } else {
            setOpen(!open);
        }
    };

    const handleDrawerOpen = () => {
        if (isMediumMD) {
            setOpenDrawerSmall(true);
            setIsShowDrawer(false);
        } else {
            setOpen(!open);
        }
    };

    const changeIsShowDrawer = () => {
        setIsShowDrawer(!isShowDrawer);
    };

    let ListDrawItem: DrawerItem[] = [
        {
            id: DrawerPage.Cameras,
            label: t('drawer.cameras'),
            icon: <CameraIcon />,
            path: '/cameras',
        },
        {
            id: DrawerPage.MapOverview,
            label: t('drawer.mapOverview'),
            icon: <MapOverviewIcon />,
            path: 'map-overview',
        },
        {
            id: DrawerPage.ZoneDashboard,
            label: t('drawer.zoneDashboard'),
            icon: <ZoneDashboardIcon />,
            path: 'zone-dashboard',
        },
        {
            id: DrawerPage.Report,
            label: 'Insights',
            icon: <IoAnalyticsOutline fontSize={28} />,
            path: '/insights',
        },
        {
            id: DrawerPage.ReportHeatMap,
            label: 'Bubble & heat map',
            icon: <TbChartBubble fontSize={28} />,
            path: '/bubble-heat-map',
        },
        {
            id: DrawerPage.Verification,
            label: t('drawer.verification'),
            icon: <VerificationIcon />,
            path: '/verification',
        },
        {
            id: DrawerPage.OverviewVerification,
            label: t('drawer.VerificationsOverview'),
            icon: <DashboardIcon />,
            path: '/overview-verification',
        },
        {
            id: DrawerPage.OverviewCameras,
            label: t('drawer.CameraOverview'),
            icon: <CameraOverview />,
            path: '/camera-overview',
        },
        // {
        //     id: DrawerPage.ReportsAnalyzing,
        //     label: t('drawer.ReportsAnalyzing'),
        //     icon: <TbChartHistogram fontSize={26} stroke={color.grey600} className="icon-svg-stroke" />,
        //     path: '/reports-analysing',
        // },
        {
            id: DrawerPage.TaskManagement,
            label: t('drawer.TaskManagement'),
            icon: <TaskManagement />,
            path: '/task-management',
        },

        // {
        //     id: DrawerPage.CameraTime,
        //     label: 'Camera Time',
        //     icon: (
        //         <IoIosTimer
        //             style={{
        //                 width: 26,
        //                 height: 26,
        //             }}
        //         />
        //     ),
        //     path: '/cameraTime',
        // },

        // {
        //     id: DrawerPage.Dashboard,
        //     label: t('drawer.dashboard'),
        //     icon: <DashboardIcon />,
        //     path: '/dashboard',
        // },
    ];

    useEffect(() => {
        isMobileSM ? setIsShowDrawer(false) : setIsShowDrawer(true);
    }, [isMobileSM]);

    return {
        ListDrawItem,
        open,
        setOpen,
        handleDrawerClose,
        // logout,
        activeItem,
        setActiveItem,
        isShowDrawer,
        changeIsShowDrawer,
        setIsShowDrawer,
        handleDrawerOpen,
        isMobileSM,
        isMediumMD,
        openDrawerSmall,
        setOpenDrawerSmall,
    };
}

export const DrawerContext = React.createContext<ReturnType<typeof useDrawer>>({} as any);
