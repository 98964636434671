import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { IMetabaseHttpController } from '../IMetabaseHttpController';

export class MetabaseHttpController extends BaseHttpController<{}> implements IMetabaseHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'metabase', client);
    }

    async getUrlDashboard(id: number): Promise<string> {
        return await this.doGet({ path: `dashboard/${id}` }).then((res) => res.data);
    }
}
