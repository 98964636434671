import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Backdrop, Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useCameraOverview } from '@pages/camerasOverview/hook/CameraOverviewProvider';
import { GridBorderRadius, GridWithGray100 } from '@pages/dashboard/styled';
import color from '@theme/Colors';
import { ReactNode } from 'react';
import BarChartCameraStatus, { BarChart } from '../charts/BarChartCameraStatus';
import TabsData from '../tabs/useTabsData';
import ScrollContainer from 'react-indiana-drag-scroll';

type Props = IPopUp & {};

export default function PopUpAvgWorkedHours(props: Props) {
    const { cameraOverview, loadingDailyWorkedHours } = useCameraOverview();

    return (
        <>
            <PopUpBase
                title={<Typography variant="h4">Camera overview: {cameraOverview?.cameraName}</Typography>}
                dialogProps={{
                    fullWidth: true,
                    maxWidth: 'lg',
                    sx: {
                        '& .MuiDialog-paper': {
                            borderRadius: '10px',
                        },
                    },
                }}
                minWidthButton={130}
                open={props.open}
                onClose={props.onClose}
                desc={
                    <>
                        <TabsData />
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loadingDailyWorkedHours}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </>
                }
                hideConfirm
            />
        </>
    );
}

export const ChartItem = ({
    title,
    data,
    chartColor,
    label,
    dataAxisY,
    dataBattery,
    avgUptime,
}: {
    title: ReactNode;
    data: BarChart[];
    chartColor: string;
    label: string;
    dataAxisY: boolean;
    dataBattery?: boolean;
    avgUptime?: ReactNode;
}) => {
    return (
        <GridWithGray100 container item md={12} sm={12} xs={12} borderRadius={'10px'}>
            <GridBorderRadius container item border={`1px solid ${color.grey200} !important`}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item md={6} xs={12}>
                        {title}
                    </Grid>
                    <Grid item md={6} xs={12} textAlign={{ xs: 'start', md: 'end' }}>
                        <Typography
                            sx={{
                                '.MuiTypography-root': {
                                    color: '#007bff',
                                },
                            }}
                        >
                            {avgUptime}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                        '& .chart-scroll-bar': {
                            '&::-webkit-scrollbar': {
                                height: '5px',
                            },

                            '&::-webkit-scrollbar-track': {
                                WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                height: '5px',
                                backgroundColor: chartColor,
                                borderRadius: '3px',
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                                cursor: 'pointer',
                                backgroundColor: chartColor,
                            },
                            '&::-webkit-scrollbar:vertical': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <ScrollContainer
                        className="container chart-scroll-bar"
                        style={{
                            height: '100%',
                            width: '100%',
                            paddingBottom: '5px',
                            position: 'relative',
                        }}
                        hideScrollbars={false}
                    >
                        <Box
                            width={{
                                md: '100%',
                                sm: data?.length! > 7 ? `${data?.length! * 70}px` : '100%',
                                xs: data?.length! > 4 ? `${data?.length! * 70}px` : '100%',
                            }}
                            height={'100%'}
                            pb={4}
                            position="relative"
                        >
                            <BarChartCameraStatus
                                dataBattery={dataBattery}
                                dataAxisY={dataAxisY}
                                data={data}
                                chartColor={chartColor}
                                label={label}
                            />
                        </Box>
                    </ScrollContainer>
                    {/* <Box position={'relative'} width={'100%'} height={'100%'}>
                        <ScrollContainer
                            className="container chart-scroll-bar"
                            style={{
                                height: '100%',
                                width: '100%',
                                paddingBottom: '5px',
                                position: 'relative',
                            }}
                            hideScrollbars={false}
                        >
                            <Box
                                width={{
                                    lg: dataLength > 30 ? `${dataLength * 50}px` : '100%',
                                    md: dataLength > 10 ? `${dataLength * 70}px` : '100%',
                                    sm: dataLength > 7 ? `${dataLength * 70}px` : '100%',
                                    xs: dataLength > 4 ? `${dataLength * 70}px` : '100%',
                                }}
                                height={'100%'}
                                pb={4}
                                position="relative"
                            >
                                <BarChartCameraStatus data={data} chartColor={chartColor} label={label} />
                            </Box>
                        </ScrollContainer>
                    </Box> */}
                </Grid>
            </GridBorderRadius>
        </GridWithGray100>
    );
};
