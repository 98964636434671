import MarkerCameraIcon from '@assets/jsx-icon/MarkerCameraIcon';
import MarkerLocationIcon from '@assets/jsx-icon/MarkerLocationIcon';
import BaseIcon from '@components/BaseIcon';
import { Camera, Location, LocationSMStatus, PurposeType } from '@EcamModel/model';
import { Chip, Stack, Typography } from '@mui/material';
import { LocationStatusReadableMapping } from '@pages/cameras/details/components/CameraInformation';
import { purposesReadableMapping } from '@pages/cameras/list/components/CameraItem';
import { PriorityColor } from '@pages/locations/components/LocationItems';
import { BaseSections, Coordinate } from '@pages/maps/configs';
import { Annotation } from '@pages/maps/WrapperGoogleMaps';
import color from '@theme/Colors';
import theme from '@theme/Theme';
import _ from 'lodash';
import { CSSProperties } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export type SelectedMarker = {
    type?: 'location' | 'camera';
    selectedItem?: Location | Camera;
    positions?: Coordinate;
    isOpen: boolean;
    relatedLocationId?: number;
};

type AnnotationsParams = {
    width: CSSProperties['width'];
    height: CSSProperties['height'];
};

const NA_TEXT = 'N/A';

export const getStatusLocationColor = (status: LocationSMStatus) => {
    switch (status) {
        case LocationSMStatus.Prospective:
            return { colorText: color.secondary, bgColor: '#E5F2FF' };
        case LocationSMStatus.Live:
            return { colorText: color.success, bgColor: '#E5F5ED' };
        case LocationSMStatus.Transferred:
            return { colorText: '#CC0066', bgColor: '#CC00661A' };
        case LocationSMStatus.Decommissioned:
            return { colorText: color.danger, bgColor: '#FCE8E5' };
        case LocationSMStatus.OnHold:
            return { colorText: '#FFB600', bgColor: '#FFF8E5' };
        default:
            return { colorText: color.danger, bgColor: '#FCE8E5' };
    }
};

export const getStatusCameraColor = (status: PurposeType) => {
    switch (status) {
        case PurposeType.Prospective:
            return { colorText: color.secondary, bgColor: '#E5F2FF' };
        case PurposeType.Live:
            return { colorText: color.success, bgColor: '#E5F5ED' };
        case PurposeType.Testing:
            return { colorText: '#CC0066', bgColor: '#CC00661A' };
        case PurposeType.Decommissioned:
            return { colorText: color.danger, bgColor: '#FCE8E5' };
        case PurposeType.OnHold:
            return { colorText: '#FFB600', bgColor: '#FFF8E5' };
        default:
            return { colorText: color.danger, bgColor: '#FCE8E5' };
    }
};

export const priorityColors: Record<number, PriorityColor> = {
    1: {
        key: 1,
        bgColorPriority: '#F8D7DA',
    },
    2: {
        key: 2,
        bgColorPriority: '#FFF3CD',
    },
    3: {
        key: 3,
        bgColorPriority: '#CCE5FF',
    },
    4: {
        key: 4,
        bgColorPriority: '#E2E3E5',
    },
    5: {
        key: 5,
        bgColorPriority: '#E5F2FF',
    },
};

export const getPriorityLocationsColor = (status: number) => {
    return (
        priorityColors[status] ?? {
            key: 0,
            bgColorPriority: '#FCE8E5',
        }
    );
};

export const getAnnotations = ({ width, height }: AnnotationsParams): Annotation[] => {
    return [
        {
            icon: <MarkerCameraIcon width={width} height={height} />,
            title: `The coordinates of the center of the camera`,
        },
        {
            icon: <MarkerLocationIcon fill={'#E01B00'} width={width} height={height} />,
            title: `The coordinates of the center of the designated location`,
        },
        {
            icon: <MarkerLocationIcon fill={'#FF8C00'} width={width} height={height} />,
            title: 'The coordinates of the center locations with cameras',
        },
    ];
};

export const getIconUrl = (isSelectedMarker: boolean): string =>
    isSelectedMarker ? '/assets/images/markerLocationSelected.svg' : `/assets/images/markerLocation.svg`;

export const LocationInformation = (location: Location): BaseSections[] => {
    const camraOfLocation = location?.Zones?.reduce((total, zone) => total + (zone.Cameras?.length || 0), 0);
    const addresses = [location?.Address1, location?.Address2, location?.Address3].filter(Boolean).join(', ');
    const { colorText, bgColor } = getStatusLocationColor(location.LocationStatus ?? 3);

    const handleClickNavigateCameraScreen = (locationId: number) => {
        if (locationId) {
            const taskScreenUrl = `/cameras?locationId=${locationId}&status=2&cameraType=0`;
            window.open(taskScreenUrl, '_blank');
        }
    };

    return [
        {
            label: (
                <Stack direction={'row'} spacing={1} alignItems={'center'} mr={2}>
                    <Typography
                        variant="h5"
                        onClick={() => (camraOfLocation ? handleClickNavigateCameraScreen(location.Id!) : undefined)}
                        sx={
                            camraOfLocation
                                ? { cursor: 'pointer', '&:hover': { color: color.priText }, textDecoration: 'none' }
                                : {}
                        }
                    >
                        {location.Name}
                    </Typography>

                    {!_.isNil(location.LocationStatus) && (
                        <Typography
                            color={colorText}
                            sx={{
                                padding: '4px 8px',
                                fontSize: 12,
                                background: bgColor,
                                borderRadius: '5px',
                            }}
                        >
                            {LocationStatusReadableMapping[location.LocationStatus!] ?? NA_TEXT}
                        </Typography>
                    )}
                </Stack>
            ),
            value: camraOfLocation ? (
                <BaseIcon
                    title="View details"
                    colorHover={theme.palette.secondary.main}
                    children={<IoEyeOutline size={14} />}
                    onClick={() => handleClickNavigateCameraScreen(location.Id!)}
                />
            ) : (
                <></>
            ),
        },
        {
            label: 'Camera',
            value: <Typography>{camraOfLocation}</Typography>,
        },
        {
            label: 'Address',
            value: <Typography color={color.grey600}>{addresses}</Typography>,
        },
    ];
};

export const CameraInformation = (camera: Camera): BaseSections[] => {
    const { colorText, bgColor } = getStatusCameraColor(camera.PurposeType ?? 3);

    return [
        {
            label: (
                <Stack direction={'row'} spacing={1} alignItems={'center'} mr={2}>
                    <Typography
                        variant="h5"
                        component={Link}
                        to={`/cameras/${camera.Id}`}
                        target="_blank"
                        sx={{ cursor: 'pointer', '&:hover': { color: color.priText }, textDecoration: 'none' }}
                    >
                        {camera?.Name}
                    </Typography>

                    {camera.PurposeType !== null && (
                        <Chip
                            size="small"
                            sx={{
                                borderRadius: '16px',
                                fontSize: 12,
                                px: '5px',
                                background: bgColor,
                                color: colorText,
                            }}
                            label={purposesReadableMapping[camera.PurposeType!]}
                        />
                    )}
                </Stack>
            ),
            value: (
                <Link to={`/cameras/${camera.Id}`} target="_blank">
                    <BaseIcon
                        title="View details"
                        colorHover={theme.palette.secondary.main}
                        children={<IoEyeOutline size={14} />}
                    />
                </Link>
            ),
        },
        { label: 'MAC', value: <Typography>{camera?.Solar4gCamera?.Mac}</Typography> },
        {
            label: 'Type',
            value: (
                <Typography color={color.secondary}>{camera.VendorCameraId ? 'Camera Solar' : 'Camera PTZ'}</Typography>
            ),
        },
    ];
};

export const checkZoom = (map: google.maps.Map | null) => {
    return map?.getZoom() ? map.getZoom()! < 15 : false;
};
