import GetBooleanFromEnv from 'src/helpers';

export const appConfig = {
    apps: {
        stella: process.env.REACT_APP_STELLA_URL!,
        firmVersion: process.env.REACT_APP_SOLAR4G_CAMERA_FIRMWARE_LATEST_VERSION!,
        firmTesting: process.env.REACT_APP_SOLAR4G_CAMERA_FIRMWARE_TESTING!,
    },
    gateway: {
        backendURL: process.env.REACT_APP_BACKEND_URL!,
        blobURL: process.env.REACT_APP_PHOTO_BLOB_URL!,
        cameraInstalledPhotoBlobURL: process.env.REACT_APP_CAMERA_INSTALLED_PHOTO_BLOB_URL!,
        cameraTaskAttachmentBlobURL: process.env.REACT_APP_CAMERA_TASK_ATTACHMENTS_BLOB_URL!,
    },
    flagFeatures: {
        multiLanguage: GetBooleanFromEnv(process.env.REACT_APP_MULTI_LANGUAGE!),
    },
    report: {
        powerBIReportId: process.env.REACT_APP_POWER_BI_REPORT_ID || '',
        powerBIReportHeatMapId: process.env.REACT_APP_POWER_BI_REPORT_HEAT_MAP_ID || '',
    },
    apiGoogle: {
        googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
    },
    blobFiles:{
        logFilesUrl: process.env.REACT_APP_FILES_BLOB_URL || '',
    },
    metabase: {
        zoneDashboardId: process.env.REACT_APP_ZONE_DASHBOARD_ID || '',
    },
};
